(function($) {
    'use strict';
    //Validate that required dependencies are available to load helpers
    //functions
    if (window.$ && window.localforage && window.Promise) {

        $.extend({
            /**
            * Promised based Ajax request
            * @param{Object} requestParams - Request parameters for request
            */
            promisedAjax: function(requestParams) {
                return new Promise(function(resolve, reject) {
                    if (!requestParams.url) {
                        reject(new Error('Missing data or url parameters for request.'))
                    } else {
                        $.ajax({
                            //Default request type is GET
                            type: requestParams.type || 'GET',
                            url: requestParams.url,
                            data: requestParams.data || null,
                            contentType: requestParams.contentType || 'application/x-www-form-urlencoded; charset=UTF-8',
                            dataType: requestParams.dataType,
                            success: function(response) { resolve(response); },
                            error: function(error) { reject(error); }
                        })
                    }
                })
            },
            /**
             * Validate a image file size and format
             * @params {Object} - key: {name, size}
             */
            validateImage : function(imageData) {
                const maxFileSize = 52428800; // 50MByte in bytes
                const isValidFormat = new RegExp(/\.(jpe?g|png|gif|bmp)$/i);
                return (isValidFormat.test(imageData.name) && imageData.size < maxFileSize);
            },
            /**
             * Generate a random string with given length
             * @param{Number} strLength
             */
            generateRandomString : function(strLength) {
                const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
                let result = '';
                for (let i = strLength; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];
                return result;
            },
            /**
             * Validate string input
             * @param{String} str
             */
            isEmpty: function(str) {
                return !str || str.trim().length == 0;
            },

            /**
             * Get user current location address details
             * @param {Object} options - Optionally pass lat and lng to get address
             */
             /* this is not used any where in website */
            /*getCurrentLocation: function(options) {
                const settings = $.extend({}, options);
                settings.detectCurrentPosition = () => {
                    return new Promise((resolve, reject) => {
                        if(!check_iOSapp() && !check_androidApp()){
                            if (!navigator.geolocation) {
                                reject(new Error('Geolocation detection not supported or is disabled'));
                            }
                            

                            var is_safari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                            if (is_safari){
                            var googlelocationValue=Cookies.get('locationPermission');
                            if(googlelocationValue!='granted'){
                                navigator.geolocation.getCurrentPosition((position) => {
                                const coordinates = {
                                    lat: position.coords.latitude,
                                    lng: position.coords.longitude
                                };
                                resolve(coordinates);
                                }, (err) => reject(err));
                            }
                            }else{
                                navigator.geolocation.getCurrentPosition((position) => {
                                const coordinates = {
                                    lat: position.coords.latitude,
                                    lng: position.coords.longitude
                                };
                                    resolve(coordinates);
                                }, (err) => reject(err));
                            }

                        } else {
                            const coordinates = {
                                lat: $("#nativeAppLatitude").val(),
                                lng: $("#nativeAppLongitude").val()
                            };
                            resolve(coordinates);
                        }
                    })
                };
            },*/
            /**
            * Retrieve an user default  Address from profile
            */
            getUserProfileAddress : (userId) => {
                if (userId) {
                    return $.promisedAjax({
                        url: `/users/profile/address/${userId}`
                    })
                } else throw new Error('userId required to get Address');
            },
            objectKeysToCamelCase : (obj) => {
                let formattedObj = {};
                for (let key in obj) {
                    let formattedKey = $.strToCamelCase(key);
                    formattedObj[formattedKey] = obj[key];
                }
                return formattedObj;
            },
            strToCamelCase : (str) => {
                return str.replace(/['_'](.)/g, (match, chr) => chr.toUpperCase());
            }
        });
        /**
         * Get venue distance in miles from venue address to user address
         * Appends the results to given element getVenueDistance was called on
         * @param{Object} options - options to get venue Distance
         */
        $.fn.getVenueDistance = function(options) {
            //SET default settings
            var settings = $.extend({
                extraClasses: 'green',
                containerElement: '<label></label>',
                childElement: '<span></span>',
                venueAddress: null,
                userAddress: null,
                venueName: null,
                //enableCaching: false //TODO: Make caching optional
            }, options);

            //Construct the parent element and add any classes
            //to its child if provided
            var $parentElement = $(settings.containerElement);
            var $childElement = $(settings.childElement).addClass(settings.extraClasses);
            var $thisContainer = $(this);

            //Check if required params for request were provided
            if (settings.venueAddress && settings.userAddress) {
                var requestData = {
                    venueName: settings.venueName,
                    venueAddress : settings.venueAddress,
                    userAddress : settings.userAddress
                };

                //Check if data is already cached
                localforage.getItem(requestData.venueName+'distance')
                .then(function(cachedVenue) {
                    if (cachedVenue) {
                        if (cachedVenue.userAddress === requestData.userAddress) {
                            //Append the distance with cached venue distance
                             //Add the venue this distance to the child element
                             var childElValue = $childElement.html(cachedVenue.distance);
                            //Append the child element value
                            $parentElement.html(childElValue).append(' away');
                            return $thisContainer.append($parentElement);
                        } else {
                            throw new Error('User Address changed');
                        }
                    } else {
                        throw new Error('Distance cached not found');
                    }
                })
                .catch(function(e) {
                     //Venue was not cached
                     //Send request to get venue distance
                    $.promisedAjax({
                        type: 'POST',
                        data: requestData,
                        url: '/venueDistance'
                    })
                    .then(function(venueDistance) {
                        if (venueDistance) {
                            //cache results
                            requestData.distance = venueDistance;
                            localforage.setItem(requestData.venueName+'distance', requestData);

                            //Add the venue this distance to the child element
                            var childElValue = $childElement.html(venueDistance);
                            //Append the child element value
                            $parentElement.html(childElValue).append(' miles away');

                        } else {
                            $parentElement.html('distance not found');
                        }
                        return $parentElement;
                    })
                    .catch(function(error) {
                        console.log('Unable to get venue distance');
                    })
                    .then(function(finalElement) {
                        //Apend the contructed element to this DOM container
                        return $thisContainer.append(finalElement);
                    })
                })

            } else {
                $thisContainer.append($parentElement.html('distance not found'));
            }
        }

        /**
         * Get yelp ratings for a venue
         * @param{Object} requestParams
         */
        $.fn.getYelpRatings = function(options) {
             //SET default settings
            var settings = $.extend({
                containerClass: 'yelp-rating-stars',
                yelpLogoClass: 'yelp-logo-img',
                containerElement: '<div> </div>',
                yelpLogoImg: '/images/yelp-images/yelp_logo_small.png',
                googleLogoImage: '/images/yelp-images/google-logo-sm.png',
                notFoundMessage: '<p class="rating-text"></p>',
                noRating: '<p class="rating-text"> no rating</p>',
            }, options);

            settings.createYelpReviewsContainer = function(data) {
                var reviewText = data.review_count === 1 ? 'review' : 'reviews'
                let ratingImg = '';
                let has_half_star = (data.rating * 10) % 10;
                let star_count = Math.floor(data.rating);
                if(has_half_star) {
                    ratingImg = `/images/yelp-images/extra_large_${star_count}_half.png"`;
                } else {
                    ratingImg = `/images/yelp-images/extra_large_${star_count}.png"`;
                }
                var $yelpParentDiv = $(`<div class="d-flex align-items-center"></div>`);
                var $yelpRatingsImg = $(`<img style="height: 10px;display:unset" src="${ratingImg}"/>`);
                const $ratingsCount = $(`<span class="yelp-rating-count" style="color:#000000!important;">${data.review_count || 0} </span>`)
                $yelpParentDiv.append($yelpRatingsImg)
                .append($ratingsCount)
                var $yelpImg = $(`<label  style="width:40px;margin:0;padding-left:3px;"><img src="${settings.yelpLogoImg}" style="display:unset;width:100%" class=${settings.yelpLogoClass} /></label>`);
                var $ratingsContainer = $(settings.containerElement)
                .addClass(settings.containerClass)
                .append($yelpImg)
                .append($yelpParentDiv)
                
                return `<div class="col-md-7 col-6 pl-0 pr-0" style="float:right; display:flex;">${$ratingsContainer.html()}</div>`;
            }
            settings.createGoogleReviewsContainer = function(data) {
                let userRatingsTotal = data.userRatingsTotal || data.user_ratings_total
                var reviewText = userRatingsTotal === 1 ? 'Google review' : 'Google reviews'
                let ratingImg = '';
                let has_half_star = (data.rating * 10) % 10;
                let star_count = Math.floor(data.rating);
                let count_rating = Number(data.rating).toFixed(1);
                if(has_half_star) {
                    ratingImg = `/images/yelp-images/google_star_${star_count}_half.png"`;
                } else {
                    ratingImg = `/images/yelp-images/google_star_${star_count}.png"`;
                }
                var $yelpRatingsImg = $(`<img style="height: 10px;display:unset" src="${ratingImg}"/>`);
               // const $ratingsCount = $(`<span class="yelp-rating-count" style="color:#000000!important;">${userRatingsTotal || 0}</span>`)
                // var $googleImg = $(`<span class="google-text-count">${count_rating}</span>`);
                var $parentStarDiv = $(`<div class="font-10 mt-1">Google </div>`);
                $parentStarDiv.append($yelpRatingsImg)
                // .append($googleImg)
                
                var $ratingsContainer = $(settings.containerElement)
                .addClass(settings.containerClass)
                .append($parentStarDiv)
                //.append($ratingsCount)
                return `<div class="col-md-5 col-6 pl-0 pr-md-0" style="float:left" >${$ratingsContainer.html()}</div>`;
            }

            var $thisContainer = $(this);
            //Check if we have required params
            if (settings.requestParams) {
                var venueParams = settings.requestParams;
                if ((venueParams.phone || venueParams.location) &&
                    venueParams.lat && venueParams.lng && venueParams.name)
                    {
                        //Send request to get yelp ratings
                        $.promisedAjax({
                             url: '/yelpdata',
                             data: venueParams
                        })
                        .then(function({yelp, google}) {
//          console.log("in helpers getYelpRatings.response for yelp google..", yelp, google);
                            let containerReviews = '';
                            if (google && google.rating) {
                                containerReviews += settings.createGoogleReviewsContainer(google);
                            }
                            if (yelp && yelp.foundBusiness) {
                                var business = yelp.yelpRestaurantData;
                                if(business.rateandreview){
                                    containerReviews += settings.createYelpReviewsContainer(business);
                                }
                            }

                            return containerReviews ? containerReviews : $(settings.notFoundMessage);
                        })
                        .catch(function(error) {
                            return $(settings.notFoundMessage);
                        })
                        .then(function(ratingsContainer) {
                            $thisContainer.html(ratingsContainer);
                        })
                    } else {
                        $thisContainer.html($(settings.notFoundMessage));
                    }
            } else {
                $thisContainer.html($(settings.notFoundMessage));
            }
        }

    } else {
        console.error('Unable to load helpers functions, missing some required dependecies');
    }
})(jQuery)
